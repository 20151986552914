















import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import RERService from "@/services/request/RERService";
import RER_DTO from "@/dto/request/rer/RER_DTO";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {PersonSex, ResidencyStatus} from "@/constants/PersonConstants";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PersonSearch from "@/components/common/PersonSearch.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import EmployeeRER from "@/components/request/rer/EmployeeRER.vue";
import {REREmployeeStatus} from "@/constants/request/RERConstants";

const AppModule = namespace("App");

@Component({
  computed: {
    REREmployeeStatus() {
      return REREmployeeStatus
    },
    PersonDTO() {
      return PersonDTO
    },
    PersonSex() {
      return PersonSex
    },
    ResidencyStatus() {
      return ResidencyStatus
    }
  },
  components: {EmployeeRER, PersonSearch, PortalDate, PortalInput, SingleFileHolder, PortalCollapse}
})
export default class RER extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  request: RER_DTO | null = null;

  mounted(){
    this.loadRequest();
  }

  get id(){
    return Number.parseInt(this.$route.params.id);
  }

  loadRequest(){
    this.startLoading();
    RERService.getById(this.id).then(
        ok => {
          this.request = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }




}
