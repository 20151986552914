






























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import PersonSearch from "@/components/common/PersonSearch.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import {PersonSex, ResidencyStatus, SSNType, StateIDSubtype} from "@/constants/PersonConstants";
import {PublicREREmployeeDTO} from "@/dto/request/rer/PublicREREmployeeDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import {namespace} from "vuex-class";
import EmployeeService from "@/services/EmployeeService";
import {EmployeeFilter} from "@/dto/payroll/Filters";
import PersonService from "@/services/PersonService";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import CompleteREREmployeePayload, {CitizenPassportDataDTO, EADDataDTO, GreenCardDataDTO} from "@/dto/request/rer/CompleteREREmployeePayload";
import RERService from "@/services/request/RERService";
import {REREmployeeStatus} from "@/constants/request/RERConstants";
import {ifValid} from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  computed: {
    SSNType() {
      return SSNType
    },
    StateIDSubtype() {
      return StateIDSubtype
    },
    PersonSex() {
      return PersonSex
    },
    ResidencyStatus() {
      return ResidencyStatus
    }
  },
  components: {PortalCheckbox, PortalAddress, PortalCollapse, PortalInput, SingleFileHolder, PortalDate, PersonSearch}
})
export default class EmployeeRER extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  employee!: PublicREREmployeeDTO;

  payload = new CompleteREREmployeePayload();

  createPerson = false;

  selectedPerson: PersonDTO | null = null;

  foundEmployees: Array<EmployeeDto> = [];

  selectedEmployee: EmployeeDto | null = null;

  mounted() {
    this.initPayload();
    // this.loadPerson();
  }

  initPayload() {
    this.payload.id = this.employee.id;
    this.payload.residencyStatus = this.employee.residencyStatus;
    switch (this.employee.residencyStatus!) {
      case ResidencyStatus.CITIZEN:
        this.payload.passport = new CitizenPassportDataDTO()
        this.payload.passport.file = this.employee.citizenPassportImage;
        break;
      case ResidencyStatus.PERMANENT_RESIDENT:
        this.payload.greenCard = new GreenCardDataDTO()
        this.payload.greenCard.file = this.employee.greenCardImage;
        break;
      case ResidencyStatus.NON_CITIZEN:
        this.payload.ead = new EADDataDTO();
        this.payload.ead.front = this.employee.eadFrontImage;

        this.payload.ead.back = this.employee.eadBackImage;
        break;
    }
    this.payload.ssn.file = this.employee.ssnImage;
    this.payload.stateID.front = this.employee.stateIdFrontImage;
    this.payload.stateID.back = this.employee.stateIdBackImage;
    console.log(JSON.stringify(this.payload))
  }

  loadPerson() {
    if (this.employee.personId) {
      this.startLoading();
      PersonService.getById(this.employee.id).then(
          ok => {
            this.selectedPerson = ok.data;
            this.stopLoading();
          },
          err => {
            console.log(JSON.stringify(err));
            this.stopLoading();
          }
      )
    }
  }

  onPersonSelected(person: PersonDTO) {
    this.startLoading();
    this.payload.personId = person.id;
    const filter = new EmployeeFilter();
    filter.detailsId = person.id;
    EmployeeService.findByFilter(filter).then(
        ok => {
          this.foundEmployees = ok.data.data.filter(e => e.employer?.id == this.employee.companyId);
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading()
        }
    )
  }

  save(){
    ifValid( this, () => {
      this.startLoading();
      RERService.completeEmployeeRegistration(this.payload).then(
          ok => {
            this.employee.status = REREmployeeStatus.COMPLETED;
            this.stopLoading();
          },
          err => {
            console.log(JSON.stringify(err));
            this.stopLoading();
          }
      )
    })
  }

  employeeChanged(){
    if(this.selectedEmployee){
      this.payload.employeeId = this.selectedEmployee.id
    } else {
      this.payload.employeeId = null
    }
  }

}
